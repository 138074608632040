  @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');




* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Encode Sans Expanded', sans-serif;
}

html,
body,
#root,
.app,
.content {
    height: 100%;
    width: 100%;
    font-family: 'Source Sans Pro', sans-serif;
}

.app {
    display: flex;
    position: relative;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb {
    background: #555;
}